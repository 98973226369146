<template>
  <div v-if="ous.length > 0">
    <b-overlay :show="isLoading || isSaving">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Organization unit</b-card-title>
        </b-card-header>
        <b-card-body
          style="max-height: 600px; overflow: auto"
        >
          <p v-if="ous.length === 0">
            No organization units defined. Add organization unit to manage company structure.
          </p>

          <v-jstree
            :data="ousTreeview"
            size="large"
            show-checkbox
            whole-row
            multiple
            text-field-name="displayName"
            value-field-name="id"
            children-field-name="children"
            @item-click="onItemClicked"
          />

          <b-form-checkbox
            v-if="ous.length > 0"
            switch
            :disabled="!$can('__special_managePermission')"
            :checked="hasAccessToDefaultOu"
            @change="onChangeAccessToDefaultOu"
          >
            Has access to systems without Organization Unit
          </b-form-checkbox>

        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BCardTitle, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import VJstree from 'vue-jstree'
import OuService from '@/service/ou.service'
import UserService from '@/service/user.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
    VJstree,
    BFormCheckbox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ous: [],
      isLoading: false,
      isSaving: false,
      userPermissions: [],
    }
  },
  computed: {
    ousTreeview() {
      // Unflatten array
      // https://stackoverflow.com/a/55241491
      const nest = (items, id = null, parentSelected = false) => items
        .filter(item => item.parentId === id)
        .map(item => ({
          ...item, children: nest(items, item.id, item.selected || parentSelected), opened: true, selected: item.selected || parentSelected,
        }))

      return nest(this.ous)
    },
    hasAccessToDefaultOu() {
      const accessToDefaultOuPermission = this.userPermissions.find(x => x.name === 'Core.Users.AccessDefaultOu')
      if (accessToDefaultOuPermission) {
        return accessToDefaultOuPermission.isGranted
      }

      return false
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    onChangeAccessToDefaultOu(val) {
      this.isLoading = true

      // find accessDefaultOu permission and update isGranted
      const accessToDefaultouPermission = this.userPermissions.find(x => x.name === 'Core.Users.AccessDefaultOu')
      accessToDefaultouPermission.isGranted = val

      // map to correct object structure for API
      const permissionsToUpdate = this.userPermissions.map(x => ({ name: x.name, isGranted: x.isGranted }))

      UserService.updatePermissionsAsync(this.user.id, { permissions: permissionsToUpdate }, { impersonateAsTenant: this.user.tenantId })
        .then(() => {
          this.$toast('User permission updated.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    selectUserOus() {
      for (let i = 0; i < this.user.organizationUnits.length; i += 1) {
        const userOuId = this.user.organizationUnits[i].id
        for (let j = 0; j < this.ous.length; j += 1) {
          if (this.ous[j].id === userOuId) {
            this.ous[j].selected = true
          }
        }
      }
    },
    loadData() {
      this.isLoading = true
      OuService.getAllListAsync({ impersonateAsTenant: this.user.tenantId })
        .then(result => {
          this.ous = result.data.items
          this.selectUserOus()

          UserService.getUserPermissionsAsync(this.user.id, { impersonateAsTenant: this.user.tenantId })
            .then(userPermissionResult => {
              this.userPermissions = [].concat(...userPermissionResult.data.groups.map(x => x.permissions))
            })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // eslint-disable-next-line no-unused-vars
    onItemClicked(node, item, e) {
      for (let i = 0; i < this.ous.length; i += 1) {
        if (this.ous[i].id === item.id) {
          this.ous[i].selected = !this.ous[i].selected
        }
      }

      this.updateOus()
    },
    updateOus() {
      this.isSaving = true
      const selectedOuIds = this.ous.filter(x => x.selected === true).map(x => x.id)

      UserService.updateOusAsync(this.user.id, { ouIds: selectedOuIds }, { impersonateAsTenant: this.user.tenantId })
        .then(() => {
          this.$emit('userOuUpdated', {
            ous: this.ous.filter(x => x.selected === true),
          })
          this.$toast.success('Updated!', {
            icon: true,
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}

</script>
