<template>
  <div>
    <b-modal
      id="edit-user-role-modal"
      v-model="isShow"
      title="Edit user roles"
      no-close-on-backdrop
      @ok="save"
    >
      <b-overlay :show="isLoading || isSaving">
        <b-alert
          :show="showRoleIsMandatoryAlert"
          variant="warning"
        >
          At least one role is mandatory!
        </b-alert>

        <b-form-checkbox
          v-for="role in roles"
          :key="role.id"
          v-model="role.selected"
          :disabled="isRoleValidToUnassign(role.name) && role.selected === true"
        >
          {{ role.name | renameRoleName }}
        </b-form-checkbox>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal, BFormCheckbox, BOverlay, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import UserService from '@/service/user.service'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BOverlay,
    BAlert,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    impersonateAsTenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShow: false,
      roles: [],
      originalRoles: [],
      isSaving: false,
      isLoading: false,
      showRoleIsMandatoryAlert: false,
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'auth/userProfile',
    }),
    currentUserId() {
      return this.userProfile.id
    },
  },
  methods: {
    isRoleValidToUnassign(role) {
      // user should not be able to deselect special roles
      if (this.userId === this.currentUserId) {
        if (role === 'SystemAdmin' || role === 'TenantAdmin') {
          return true
        }
      }

      return false
    },
    show() {
      this.isShow = true
      this.isLoading = true
      this.showRoleIsMandatoryAlert = false

      Promise.all([
        UserService.getUserRolesAsync(this.userId, { impersonateAsTenant: this.impersonateAsTenantId }),
        UserService.getAssignableRolesAsync({ impersonateAsTenant: this.impersonateAsTenantId }),
      ]).then(values => {
        const assignedRoles = values[0].data.items
        this.roles = values[1].data.items

        for (let i = 0; i < this.roles.length; i += 1) {
          if (assignedRoles.some(x => x.id === this.roles[i].id)) {
            this.roles[i].selected = true
          }
        }

        this.originalRoles = JSON.parse(JSON.stringify(this.roles))
        this.isLoading = false
      })
    },
    save(bvModalEvent) {
      const selectedRoleIds = this.roles.filter(x => x.selected === true).map(x => x.id)
      const originalAssignedRoleIds = this.originalRoles.filter(x => x.selected === true).map(x => x.id)

      const selectionChanged = selectedRoleIds.sort().join(',') !== originalAssignedRoleIds.sort().join(',')
      if (selectionChanged) {
        this.isSaving = true
        const selectedRoleNames = this.roles.filter(x => x.selected === true).map(x => x.name)

        if (selectedRoleNames.length === 0) {
          this.showRoleIsMandatoryAlert = true
          this.isSaving = false
          bvModalEvent.preventDefault()
          return
        }

        UserService.updateUserRolesAsync(this.userId, { roleNames: selectedRoleNames }, { impersonateAsTenant: this.impersonateAsTenantId })
          .then(() => {
            this.$emit('userRolesChanged', {
              userRoles: this.roles.filter(x => x.selected === true),
            })
            this.$toast.success('Updated!', {
              icon: true,
            })
          })
          .finally(() => {
            this.isSaving = false
            this.isShow = false
          })
      } else {
        this.isShow = false
      }
    },
  },
}

</script>
