<template>
  <div>
    <portal to="breadcrumb-right">
      <b-button
        v-if="canDeleteUser"
        variant="danger"
        size="sm"
        @click="deleteUser"
      >
        <feather-icon
          icon="DeleteIcon"
        /> Delete
      </b-button>
      <b-button
        v-if="isLocked && (isCurrentUserTenantAdmin || isCurrentUserSystemAdmin)"
        variant="secondary"
        size="sm"
        @click="unlockUser"
      >
        <feather-icon
          icon="UnlockIcon"
        /> Unlock
      </b-button>

      <b-dropdown
        size="sm"
        right
        variant="secondary"
        class="ml-1"
      >
        <template #button-content>
          <feather-icon
            icon="KeyIcon"
          />
          2FA
        </template>
        <b-dropdown-item
          v-if="canManage2fa && !user.twoFactorEnabled"
          @click="enableTwoFactorAuthentication"
        >
          Enable 2FA
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canManage2fa && user.twoFactorEnabled"
          @click="disableTwoFactorAuthentication"
        >
          Disable 2FA
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canManage2fa && user.twoFactorEnabled"
          @click="resetTwoFactorAuthentication"
        >
          Reset 2FA
        </b-dropdown-item>
      </b-dropdown>
    </portal>

    <b-row>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <h4
            v-if="!isHost"
            class="mb-1"
          >
            <b-link :to="{ name: 'host:manage:tenant-detail', params: { id: tenant.id} }">
              {{ tenant.name }}
            </b-link>
          </h4>

          <b-row>
            <b-col
              cols="12"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >

              <div class="d-flex justify-content-start">
                <b-avatar
                  size="104px"
                  variant="light-primary"
                  rounded
                  :src="user.profileImageUrl"
                />

                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ user.name }} {{ user.surname }}
                    </h4>
                    <span class="card-text">{{ user.email }}</span>
                  </div>
                  <div
                    class="d-flex flex-wrap mb-1"
                  >
                    <b-badge
                      v-for="staticRole in getUserStaticRoles"
                      :key="staticRole.id"
                      class="mr-1"
                      variant="primary"
                    >
                      {{ staticRole.name }}
                    </b-badge>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      v-if="!isUserImpersonated && isCurrentUserHost"
                      variant="outline-info"
                      @click="impersonate"
                    >
                      Impersonate
                    </b-button>
                  </div>
                </div>

              </div>

              <!-- User Stats -->
              <!--<div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      ?
                    </h5>
                    <small>Last active time</small>
                  </div>
                </div>
              </div>-->
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="table table-borderless mt-2 mt-xl-0 w-100">
                <tr :class="{ 'table-warning': user.isActive === false }">
                  <td class="pb-50">
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Is Active</span>
                  </td>
                  <td class="pb-50">
                    <b-form-checkbox
                      disabled
                      :checked="user.isActive"
                    />
                  </td>
                </tr>
                <tr :class="{ 'table-warning': !user.emailConfirmed }">
                  <td class="pb-50">
                    <feather-icon
                      icon="AtSignIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Email confirmed</span>
                  </td>
                  <td class="pb-50">
                    <b-form-checkbox
                      disabled
                      :checked="user.emailConfirmed"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="pb-50">
                    <feather-icon
                      icon="KeyIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">2FA</span>
                  </td>
                  <td class="pb-50">
                    <b-form-checkbox
                      disabled
                      :checked="user.twoFactorEnabled"
                    />
                  </td>
                </tr>
                <tr :class="{ 'table-warning': isLocked }">
                  <td class="pb-50">
                    <feather-icon
                      icon="LockIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Locked out</span>
                  </td>
                  <td class="pb-50 text-capitalize">
                    <b-form-checkbox
                      disabled
                      :checked="isLocked"
                    />
                  </td>
                </tr>
                <tr v-if="isLocked">
                  <td class="pb-50">
                    <feather-icon
                      icon="LockIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Lockout end (UTC)</span>
                  </td>
                  <td class="pb-50 text-capitalize">
                    {{ user.lockoutEnd | formatDateTime }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-50">
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Created on (UTC)</span>
                  </td>
                  <td class="pb-50">
                    {{ user.creationTime | formatDateTime }}
                  </td>
                </tr>
                <tr v-if="user.lastModificationTime !== null">
                  <td class="pb-50">
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Modified on (UTC)</span>
                  </td>
                  <td class="pb-50">
                    {{ user.lastModificationTime | formatDateTime }}
                  </td>
                </tr>
              </table>
            </b-col>

          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>Security Roles</b-card-title>

            <b-button
              v-if="user.id && $can('__special_managePermission')"
              variant="primary"
              size="sm"
              @click="$refs.editUserRolesModal.show()"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Change roles</span>
              <edit-user-roles-modal
                ref="editUserRolesModal"
                :user-id="user.id"
                :impersonate-as-tenant-id="tenant.id"
                @userRolesChanged="onUserRolesChanged"
              />

            </b-button>
          </b-card-header>
          <b-card-body>
            <table class="table table-borderless">
              <thead>
                <tr colspan="2">
                  <th>Security roles</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="userRole in userRoles"
                  :key="userRole.id"
                >
                  <td>{{ userRole.name | renameRoleName }}
                  </td></tr>
              </tbody>
            </table>
          </b-card-body>
        </b-card>

        <user-detail-ou-card
          v-if="user.id"
          ref="userDetailOuCard"
          :user="user"
          @userOuUpdated="onUserOuUpdated"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BAvatar, BButton, BFormCheckbox, BBadge, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import UserService from '@/service/user.service'
import OuService from '@/service/ou.service'
import TenantService from '@/service/tenant.service'
import EditUserRolesModal from '@/views/shared/user/EditUserRolesModal.vue'
import UserDetailOuCard from '@/views/shared/user/UserDetailOuCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BAvatar,
    BButton,
    BFormCheckbox,
    BBadge,
    BLink,
    EditUserRolesModal,
    UserDetailOuCard,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      user: {},
      userRoles: [],
      ous: [],
      tenant: {},
    }
  },
  computed: {
    ...mapGetters({
      isUserImpersonated: 'auth/isUserImpersonated',
      userProfile: 'auth/userProfile',
      isCurrentUserHost: 'auth/isHost',
      isCurrentUserTenantAdmin: 'auth/isTenantAdmin',
      isCurrentUserSystemAdmin: 'auth/isSystemAdmin',
    }),
    userId() {
      return this.$route.params.userid
    },
    isHost() {
      return this.user.tenantId === null
    },
    getUserStaticRoles() {
      return this.userRoles.filter(x => x.isStatic === true)
    },
    canDeleteUser() {
      // Delete is only possible if user is not the current user and user has delete permission
      return this.userProfile.id !== this.userId && this.$can('__special_managePermission')
    },
    canManage2fa() {
      // Manage 2FA is only possible for Tenant Admins and System Admins
      return this.isCurrentUserTenantAdmin || this.isCurrentUserSystemAdmin
    },
    isLocked() {
      return this.$moment.utc(this.user.lockoutEnd) > this.$moment.utc()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      UserService.getAsync(this.userId, { disableTenantFilter: true }).then(userResult => {
        this.user = userResult.data

        this.loadUserRoles()

        OuService.getAllListAsync({ impersonateAsTenant: this.user.tenantId }).then(result => {
          this.ous = result.data.items
        })

        if (this.isHost === false) {
          TenantService.getAsync(this.user.tenantId).then(result => {
            this.tenant = result
          })
        }
      })
    },
    loadUserRoles() {
      UserService.getUserRolesAsync(this.userId, { impersonateAsTenant: this.user.tenantId }).then(result => {
        this.userRoles = result.data.items
      })
    },
    impersonate() {
      this.$store.dispatch('auth/impersonate', this.userId)
    },
    onUserRolesChanged() {
      this.loadUserRoles()
    },
    onUserOuUpdated() {
      UserService.getAsync(this.userId, { disableTenantFilter: true })
        .then(userResult => {
          this.user = userResult.data
          this.$refs.userDetailOuCard.loadData()
        })
    },
    deleteUser() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          UserService.deleteAsync(this.userId, { disableTenantFilter: true })
            .then(() => {
              this.$toast('User has been deleted.')
              this.$router.go(-1) // go back by one record
            })
        }
      })
    },
    enableTwoFactorAuthentication() {
      this.$swal({
        title: 'Are you sure?',
        text: 'The user will be prompted to set up his 2FA at the next login.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, enable 2FA',
      }).then(result => {
        if (result.isConfirmed) {
          UserService.enableTwoFactorAuthentication(this.userId, { disableTenantFilter: true })
            .then(() => {
              this.$toast('Two-factor authentication enabled.')
              this.loadData()
            })
        }
      })
    },
    disableTwoFactorAuthentication() {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, disable 2FA',
      }).then(result => {
        if (result.isConfirmed) {
          UserService.disableTwoFactorAuthentication(this.userId, { disableTenantFilter: true })
            .then(() => {
              this.$toast('Two-factor authentication disabled.')
              this.loadData()
            })
        }
      })
    },
    resetTwoFactorAuthentication() {
      this.$swal({
        title: 'Are you sure?',
        text: 'The user will be prompted to set up their 2FA again the next time they log in. The old Authenticator code will no longer work.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, enable 2FA',
      }).then(result => {
        if (result.isConfirmed) {
          UserService.resetTwoFactorAuthentication(this.userId, { disableTenantFilter: true })
            .then(() => {
              this.$toast('Two-factor authentication resetted.')
              this.loadData()
            })
        }
      })
    },
    unlockUser() {
      UserService.unlock(this.userId, { disableTenantFilter: true })
        .then(() => {
          this.$toast('User unlocked.')
          this.loadData()
        })
    },
  },
}
</script>
